const acknowledgements = {
  'ui:order': ['acknowledgementsList'],
  'ui:title': '',
  acknowledgementsList: {
    'ui:title': ' ',
    'ui:widget': 'CheckboxesWidget',
  },
};

export default acknowledgements;
