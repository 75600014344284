const MAX_TEXTAREA_LENGTH = 3500;
const MAX_LONG_INPUT_LENGTH = 200;
const MAX_CONTACT_INPUT_LENGTH = 128;
const MAX_MED_INPUT_LENGTH = 75;
const MAX_SHORT_INPUT_LENGTH = 9;
const MIN_INPUT_LENGTH = 1;
const MAX_LENGTH_POSTAL_CODE = 6;

const EXCEL_FILE_EXTENSIONS = '.xls, .xlsx';
const ANALYST_EXCEL_FILE_EXTENSIONS = `.xlsm, ${EXCEL_FILE_EXTENSIONS}`;

export {
  MAX_TEXTAREA_LENGTH,
  MAX_LONG_INPUT_LENGTH,
  MAX_CONTACT_INPUT_LENGTH,
  MAX_MED_INPUT_LENGTH,
  MAX_SHORT_INPUT_LENGTH,
  MIN_INPUT_LENGTH,
  MAX_LENGTH_POSTAL_CODE,
  EXCEL_FILE_EXTENSIONS,
  ANALYST_EXCEL_FILE_EXTENSIONS,
};
